// Limits.tsx

import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import ApiService from '../../helpers/apiService';
import { v4 as uuidv4 } from 'uuid';

interface Limit {
  id: number;
  code: string;
  object_type: number;
  action_type: number;
  object_id: string;
  limit_type: number;
  value: string;
  remaining: string;
  duration: number;
  started_at: string;
  description: string;
}

const Limits: React.FC = () => {
  const [visibleTable, setVisibleTable] = useState<string>('table1');
  const [limitsData, setLimitsData] = useState<Limit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'add' | 'edit'>('add');
  const [currentLimit, setCurrentLimit] = useState<Limit | null>(null);

  const handleTableChange = (tableId: string) => {
    setVisibleTable(tableId);
  };

  const fetchLimits = async () => {
    setLoading(true);
    const requestId = uuidv4();

    try {
      const data = await ApiService.getLimits<{ data: Limit[] }>({}, requestId);
      setLimitsData(data.data);
    } catch (error) {
      console.error('Ошибка при получении лимитов:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLimits();
  }, []);

  const handleAddLimit = () => {
    setModalType('add');
    setCurrentLimit(null);
    setShowModal(true);
  };

  const handleEditLimit = (limit: Limit) => {
    setModalType('edit');
    setCurrentLimit(limit);
    setShowModal(true);
  };

  const handleDeleteLimit = async (code: string) => {
    const requestId = uuidv4();
    try {
      await ApiService.deleteLimit(code, requestId);
      fetchLimits();
    } catch (error) {
      console.error('Ошибка при удалении лимита:', error);
    }
  };

  const handleModalSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const requestId = uuidv4();
    const formData = new FormData(e.currentTarget);
    const limitData = Object.fromEntries(formData.entries());

    // Валидация и форматирование данных
    const formattedLimitData: any = {
      ...limitData,
      object_type: parseInt(limitData.object_type as string, 10) || 0,
      action_type: parseInt(limitData.action_type as string, 10) || 0,
      limit_type: parseInt(limitData.limit_type as string, 10) || 0,
      duration: parseInt(limitData.duration as string, 10) || 0,
      started_at: limitData.started_at
        ? new Date(limitData.started_at as string).toISOString()
        : new Date().toISOString()
    };

    // Проверка на отрицательные значения
    if (
      formattedLimitData.limit_type < 0 ||
      formattedLimitData.duration < 0 ||
      formattedLimitData.object_type < 0 ||
      formattedLimitData.action_type < 0
    ) {
      alert(
        'Поля limit_type, duration, object_type и action_type не могут быть отрицательными'
      );
      return;
    }

    // Добавляем 'id' при редактировании
    if (modalType === 'edit' && currentLimit) {
      formattedLimitData.id = currentLimit.id;
    }

    // Логируем данные перед отправкой
    console.log('Данные для сохранения лимита:', formattedLimitData);

    try {
      if (modalType === 'add') {
        await ApiService.addLimit(formattedLimitData, requestId);
      } else if (modalType === 'edit' && currentLimit) {
        await ApiService.updateLimit(formattedLimitData, requestId);
      }
      fetchLimits();
      setShowModal(false);
    } catch (error) {
      console.error('Ошибка при сохранении лимита:', error);
    }
  };

  return (
    <div className="Limits-page">
      <div
        className="d-flex justify-content-center mb-4"
        style={{ marginLeft: '350px' }}
      >
        {visibleTable === 'table1' && (
          <>
            <Button
              variant="primary"
              onClick={() => handleTableChange('table2')}
            >
              Добавить группа
            </Button>
            <Button variant="primary" className="mx-2" onClick={handleAddLimit}>
              Добавить лимит
            </Button>
          </>
        )}
        {visibleTable === 'table2' && (
          <>
            <Button variant="primary" className="mx-2" onClick={handleAddLimit}>
              Добавить лимит
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              onClick={() => handleTableChange('table1')}
            >
              Назад к главной таблице
            </Button>
          </>
        )}
        {visibleTable === 'table3' && (
          <>
            <Button
              variant="primary"
              className="mx-2"
              onClick={() => handleTableChange('table2')}
            >
              Добавить группа
            </Button>
            <Button
              variant="secondary"
              className="mx-2"
              onClick={() => handleTableChange('table1')}
            >
              Назад к главной таблице
            </Button>
          </>
        )}
      </div>

      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px'
        }}
      >
        {loading ? (
          <p>Загрузка...</p>
        ) : (
          <>
            {visibleTable === 'table1' && (
              <div style={{ overflowX: 'auto' }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '50px' }}>ID</th>
                      <th style={{ minWidth: '100px' }}>Код</th>
                      <th style={{ minWidth: '100px' }}>Тип объекта</th>
                      <th style={{ minWidth: '100px' }}>Тип действия</th>
                      <th style={{ minWidth: '150px' }}>ID объекта</th>
                      <th style={{ minWidth: '100px' }}>Тип лимита</th>
                      <th style={{ minWidth: '100px' }}>Значение</th>
                      <th style={{ minWidth: '100px' }}>Остаток</th>
                      <th style={{ minWidth: '100px' }}>Длительность</th>
                      <th style={{ minWidth: '150px' }}>Начало действия</th>
                      <th style={{ minWidth: '200px' }}>Описание</th>
                      <th style={{ minWidth: '100px' }}>Управление</th>
                    </tr>
                  </thead>
                  <tbody>
                    {limitsData.map(limit => (
                      <tr key={limit.id}>
                        <td>{limit.id}</td>
                        <td>{limit.code}</td>
                        <td>{limit.object_type}</td>
                        <td>{limit.action_type}</td>
                        <td>{limit.object_id}</td>
                        <td>{limit.limit_type}</td>
                        <td>{limit.value}</td>
                        <td>{limit.remaining}</td>
                        <td>{limit.duration}</td>
                        <td>{new Date(limit.started_at).toLocaleString()}</td>
                        <td title={limit.description}>
                          {limit.description && limit.description.length > 20
                            ? limit.description.substring(0, 20) + '...'
                            : limit.description}
                        </td>
                        <td>
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={() => handleEditLimit(limit)}
                          >
                            ✏️
                          </Button>{' '}
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteLimit(limit.code)}
                          >
                            🗑️
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            {/* Отображение других таблиц при необходимости */}
          </>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Form onSubmit={handleModalSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalType === 'add' ? 'Добавить лимит' : 'Редактировать лимит'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="code">
              <Form.Label>Код</Form.Label>
              <Form.Control
                type="text"
                name="code"
                defaultValue={currentLimit?.code || ''}
                required
              />
            </Form.Group>
            <Form.Group controlId="object_type">
              <Form.Label>Тип объекта</Form.Label>
              <Form.Control
                type="number"
                name="object_type"
                defaultValue={currentLimit?.object_type || 0}
                required
                min={0}
              />
            </Form.Group>
            <Form.Group controlId="action_type">
              <Form.Label>Тип действия</Form.Label>
              <Form.Control
                type="number"
                name="action_type"
                defaultValue={currentLimit?.action_type || 0}
                required
                min={0}
              />
            </Form.Group>
            <Form.Group controlId="object_id">
              <Form.Label>ID объекта</Form.Label>
              <Form.Control
                type="text"
                name="object_id"
                defaultValue={currentLimit?.object_id || ''}
              />
            </Form.Group>
            <Form.Group controlId="limit_type">
              <Form.Label>Тип лимита</Form.Label>
              <Form.Control
                type="number"
                name="limit_type"
                defaultValue={currentLimit?.limit_type || 0}
                min={0}
              />
            </Form.Group>
            <Form.Group controlId="value">
              <Form.Label>Значение</Form.Label>
              <Form.Control
                type="text"
                name="value"
                defaultValue={currentLimit?.value || ''}
              />
            </Form.Group>
            <Form.Group controlId="remaining">
              <Form.Label>Остаток</Form.Label>
              <Form.Control
                type="text"
                name="remaining"
                defaultValue={currentLimit?.remaining || ''}
              />
            </Form.Group>
            <Form.Group controlId="duration">
              <Form.Label>Длительность</Form.Label>
              <Form.Control
                type="number"
                name="duration"
                defaultValue={currentLimit?.duration || 0}
                min={0}
              />
            </Form.Group>
            <Form.Group controlId="started_at">
              <Form.Label>Начало действия</Form.Label>
              <Form.Control
                type="datetime-local"
                name="started_at"
                defaultValue={
                  currentLimit
                    ? new Date(currentLimit.started_at)
                        .toISOString()
                        .slice(0, -1)
                    : ''
                }
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Описание</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                defaultValue={currentLimit?.description || ''}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Отмена
            </Button>
            <Button variant="primary" type="submit">
              Сохранить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Limits;
