import React from 'react';
import { Table, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface User {
  userId: string;
  name: string;
  kind: string;
  start_date: string;
  update_date?: string;
  account_list: {
    account: string;
    currency: string;
    kind: string;
    type: string;
  }[];
  kyc_data?: {
    doc_id: string;
    doc_type: string;
    number: string;
    series: string;
    issue_data: string;
    exp_date: string;
    resident: string;
  }[];
  aml_data?: {
    doc_id: string;
    status: string;
    comment: string;
  }[];
  is_manager: boolean;
  is_enabled: boolean;
  data_company?: string[];
  status: string;
  scoring?: {
    manual_block: boolean;
  };
}

interface UsersTableProps {
  users: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>User ID</th>
          <th>Name</th>
          <th>Start Date</th>
          <th>Accounts</th>
          <th>KYC</th>
          <th>Manager</th>
          <th>Enabled</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <tr key={user.userId}>
            {/* User ID */}
            <td>
              <Link to={`/checks/profile?userId=${user.userId}`}>
                {user.userId}
              </Link>
            </td>

            {/* Name */}
            <td>{user.name.trim() ? user.name : 'No Name'}</td>

            {/* Start Date */}
            <td>
              {user.start_date !== '0001-01-01T00:00:00Z'
                ? user.start_date
                : 'N/A'}
            </td>

            {/* Account List */}
            <td>
              {user.account_list && user.account_list.length > 0 ? (
                <Link to={`/accounts?userId=${user.userId}`}>
                  View Accounts
                </Link>
              ) : (
                'No accounts'
              )}
            </td>

            {/* KYC Data */}
            <td>
              {user.kyc_data && user.kyc_data.length > 0 ? (
                <Link to={`/kyc/${user.userId}`}>View KYC</Link>
              ) : (
                'No KYC'
              )}
            </td>

            {/* Is Manager */}
            <td>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    {user.data_company?.length
                      ? user.data_company.join(', ')
                      : 'No companies'}
                  </Tooltip>
                }
              >
                <Form.Check
                  type="switch"
                  checked={user.is_manager}
                  readOnly
                  aria-label="Manager"
                />
              </OverlayTrigger>
            </td>

            {/* Is Enabled */}
            <td>
              <Form.Check
                type="switch"
                checked={user.is_enabled}
                readOnly
                aria-label="Enabled"
              />
            </td>

            {/* Status */}
            <td>{user.status || 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UsersTable;
