import React, { useState, useEffect } from 'react';
import UsersFilters from './UsersFilters';
import UsersTable from './UsersTable';
import ApiService from '../../../helpers/apiService';
import { Pagination } from 'react-bootstrap';

interface FilterType {
  search: string;
  type?: { value: string; label: string } | undefined;
  kind?: { value: string; label: string } | undefined;
}

const Users: React.FC = () => {
  const [filters, setFilters] = useState<FilterType>({
    search: '',
    type: undefined,
    kind: undefined
  });
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    countAll: 0
  });

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const data = await ApiService.get<{ data: any[]; total: number }>(
        '/user/v1/list',
        {
          params: {
            search: filters.search,
            type: filters.type?.value,
            kind: filters.kind?.value,
            page: pagination.page,
            limit: pagination.limit
          }
        }
      );
      setUsers(data.data);
      setPagination(prev => ({
        ...prev,
        countAll: data.total
      }));
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSubmit = () => {
    setPagination(prev => ({
      ...prev,
      page: 1 // Сбрасываем на первую страницу при новом поиске
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setPagination(prev => ({
      ...prev,
      page: pageNumber
    }));
  };

  useEffect(() => {
    fetchUsers();
  }, [pagination.page, pagination.limit, filters]);

  const totalPages = Math.ceil(pagination.countAll / pagination.limit);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    let items = [];
    // Ограничим количество отображаемых страниц для удобства
    const maxVisiblePages = 5;
    let startPage = Math.max(
      1,
      pagination.page - Math.floor(maxVisiblePages / 2)
    );
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === pagination.page}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    return (
      <Pagination className="justify-content-center">
        <Pagination.First
          onClick={() => handlePageChange(1)}
          disabled={pagination.page === 1}
        />
        <Pagination.Prev
          onClick={() => handlePageChange(pagination.page - 1)}
          disabled={pagination.page === 1}
        />
        {items}
        <Pagination.Next
          onClick={() => handlePageChange(pagination.page + 1)}
          disabled={pagination.page === totalPages}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPages)}
          disabled={pagination.page === totalPages}
        />
      </Pagination>
    );
  };

  return (
    <div>
      <UsersFilters
        values={filters}
        setFieldValue={(field, value) =>
          setFilters(prev => ({ ...prev, [field]: value }))
        }
        handleSubmit={handleFilterSubmit}
        loading={loading}
      />
      <UsersTable users={users} />
      {renderPagination()}
    </div>
  );
};

export default Users;
