import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  FormControl,
  Spinner,
  Card,
  Table,
  Accordion
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ApiService from '../../helpers/apiService';
import IconButton from '../common/IconButton';

const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDisableModal, setShowDisableModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // Извлекаем userId из searchParams
  const userId = searchParams.get('userId');

  const fetchProfileData = async (userId: string) => {
    console.log('Запрос данных для userId:', userId);
    setLoading(true);
    setError(null);
    try {
      const data = await ApiService.get<any>('/user/v1/list', {
        params: { userId },
        headers: {
          requestId: 'ваш_requestId', // Замените на ваш уникальный requestId
          Authorization: 'Bearer ваш_токен' // Замените на ваш токен авторизации
        }
      });
      console.log('Получены данные:', data);
      setProfileData(data?.data[0]);
    } catch (err) {
      console.error('Ошибка при запросе данных:', err);
      setError('Не удалось загрузить данные пользователя');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchProfileData(userId);
    }
  }, [userId]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSearchParams({ userId: searchQuery });
  };

  const closeDisableModal = () => setShowDisableModal(false);

  return (
    <div className="ProfilePage-page">
      {/* Поле поиска и кнопка */}
      <form className="d-flex gap-3 mb-5" onSubmit={handleSubmit}>
        <FormControl
          placeholder="Поиск по ФИО, телефону, номеру счёта"
          value={searchQuery}
          onChange={handleSearch}
          className="search-bar"
        />
        {/*@ts-ignore*/}
        <IconButton
          type="submit"
          className="d-flex align-items-center gap-2"
          variant="primary"
          disabled={loading}
          icon="search"
          iconAlign="left"
        >
          Поиск
          {loading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
        </IconButton>
      </form>

      {loading && <p>Загрузка...</p>}
      {error && <p>{error}</p>}

      {/* Данные профиля */}
      {profileData && (
        <Card className="ProfilePage-card mb-3 p-3 rounded-4">
          <div className="d-flex">
            <div className="flex-grow-1 user-info mb-3">
              <p>ФИО: {profileData.name || 'N/A'}</p>
              <p>Дата Рождения: {profileData.birth_date || 'N/A'}</p>
              <p>Адрес: {profileData.address || 'N/A'}</p>
              <p>Документы: {profileData.documents || 'N/A'}</p>
              <p>Проверка: {profileData.check_status || 'N/A'}</p>
              <p>Статус: {profileData.status || 'N/A'}</p>
              <p>Менеджер: {profileData.is_manager ? 'Да' : 'Нет'}</p>
            </div>
            <div className="photo-section">
              <div
                className="photo-placeholder bg-light border d-flex justify-content-center align-items-center"
                style={{ height: '210px', width: '160px' }}
              >
                Фото
              </div>
            </div>
          </div>
        </Card>
      )}

      {/* Аккордеон с информацией */}
      {profileData && (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Счета (Accounts)</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Счёт</th>
                    <th>Валюта</th>
                    <th>Баланс</th>
                  </tr>
                </thead>
                <tbody>
                  {profileData.account_list?.map(
                    (account: any, index: number) => (
                      <tr key={index}>
                        <td>{account.account}</td>
                        <td>{account.currency}</td>
                        <td>{account.sum || 'N/A'}</td>
                      </tr>
                    )
                  ) || (
                    <>
                      <tr>
                        <td>Счёт_1</td>
                        <td>USD</td>
                        <td>1000,2</td>
                      </tr>
                      <tr>
                        <td>Счёт_2</td>
                        <td>ETH</td>
                        <td>0,0025</td>
                      </tr>
                      <tr>
                        <td>Счёт_3</td>
                        <td>USDT</td>
                        <td>10,25</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Ограничения (Restrictions)</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Ограничение</th>
                    <th>Транзакции</th>
                    <th>Вывод</th>
                    <th>Перевод</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Лимит на день</td>
                    <td>Открытие счета</td>
                    <td>Вывод на внешние</td>
                    <td>Заказ карты</td>
                  </tr>
                  <tr>
                    <td>Лимит на неделю</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Лимит на месяц</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      {/* Модальное окно для отключения аккаунтов */}
      <Modal show={showDisableModal} onHide={closeDisableModal}>
        <Modal.Header closeButton>
          <Modal.Title>Выберите аккаунты для отключения</Modal.Title>
        </Modal.Header>
        <Modal.Body>Список аккаунтов для отключения</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDisableModal}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilePage;
